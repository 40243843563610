.app__navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 4rem 2rem 4rem;
}

.app__navbar-logo img {
    width: 8vw;
}

.app__navbar-links a{
    padding-left: 1.5rem;
    font-size: 16px;
}

.app__navbar-links a:hover{
    color: var(--color-green);
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transition: .5s ease;
    background-color: var(--color-black);
    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: var(--color-golden);
    cursor: pointer;

    position: absolute;
    top: 3rem;
    right: 3rem;
}

.app__navbar-smallscreen_links a {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    cursor: pointer;
    color: var(--color-green);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.app__navbar-smallscreen_links a:hover {
    color: var(--color-white);
}

@media screen and (max-width: 850px) {
    .app__navbar-links {
        display: none;
    }

    .app__navbar-smallscreen {
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .app__navbar {
        padding: 1rem;
    }

    .app__navbar-login {
        display: none;
    }
}

@media screen and (max-width: 650px) {

    .app__navbar-logo img {
        width: 15vw;
    }

}