.app__miniProject{
    margin-top: 6rem;
}

.app__miniProject img{
    width: 30vw;
    height: fit-content;
}

.app__miniProject__desc{
    width: 45%;
}

.app__miniProject__desc .head__manrope{
    margin-bottom: 2rem;
}

.app__miniProjects__desc-p{
    margin-bottom: 1rem;
}

.app__miniProject__desc-info-link{
    margin: 1rem 0;
}

.app__miniProject__desc-info-link img{
    width: 3vw;
}

@media screen and (max-width: 1200px) {
    .app__miniProject img{
       width: 35vw;
    }

    .app__miniProject__desc-info-link img{
        width: 5vw;
    }
}

@media screen and (max-width: 450px) {

    .app__miniProject__desc-info-role .p__manrope{
        font-size: 7px;
    }
}

@media screen and (max-width: 350px) {

    .app__miniProject__desc-info-role .p__manrope{
        font-size: 6px;
    }
}