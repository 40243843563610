.app__capabilities{
    margin-top: 4rem;
}

.app__capabilities__buttons{
    width: 55%;
}

.app__capabilities__buttons button{
      background-color: transparent;
      border: 1px solid;
      border-color: #484848;
      color: var(--color-white);
      font-family: var(--font-alt);
      font-weight: 400;
      letter-spacing: 0.04em;
      line-height: 50px;
      font-size: 16px;
      text-align: center;
      border-radius: 80px;
      cursor: pointer;
      padding: 0.5rem 2.5rem;
      margin-bottom: 2rem;
      margin-right: 1rem;
}

.app__capabilities__buttons button:hover{
    transform: scale(1.06);
}

@media screen and (max-width: 1150px) {
    .app__capabilities__buttons button{
        padding: 0.5rem 2rem;
        margin-right: 0.5rem;
        font-size: 14px;
    }
}

@media screen and (max-width: 850px) {
    .app__capabilities__buttons button{
        padding:0 1rem;
        margin-right: 0.5rem;
        font-size: 12px;
    }
}

@media screen and (max-width: 450px) {
    .app__capabilities__buttons button{
        font-family: var(--font-base);
        margin-right: 0.3rem;
        font-size: 9px;
        padding: 0rem 0.5rem;
        margin-bottom: 1rem;
    }

}