.bold {
    color: var(--color-green);
    font-style: bold;
}

.app__aboutfeature {
    margin-top: 4rem;
}

.app__aboutfeature__desc{
    width: 55%;
}

.app__aboutfeature__desc h1{
    margin-bottom: 2rem;
}
