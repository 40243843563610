.app__project{
    margin-top: 6rem;
}

.app__project img{
    width: 30vw;
    height: fit-content;
}

.app__project__desc{
    width: 45%;
}

.app__project__desc .head__manrope{
    margin-bottom: 2rem;
}

.app__projects__desc-p{
    margin-bottom: 1rem;
}

.app__project__desc-info-year{
    margin: 1rem 0;
}

.app__project__desc-info-role{
    margin: 1rem 0;
}

.app__project__desc-info-link{
    margin: 1rem 0;
}

@media screen and (max-width: 1200px) {
    .app__project img{
       width: 35vw;
    }
}

@media screen and (max-width: 450px) {

    .app__project__desc-info-role .p__manrope{
        font-size: 7px;
    }
}

@media screen and (max-width: 350px) {

    .app__project__desc-info-role .p__manrope{
        font-size: 6px;
    }
}