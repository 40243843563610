.App{
    background-color: var(--color-black);
}

.flex__center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app__background-color{
    background-color: var(--color-black);
  }
  
  .app__wrapper {
    display: flex;
    justify-content: space-between;
  }
  
  .custom__button {
      background-color: var(--color-green);
      color: var(--color-black);
      font-family: var(--font-base);
      font-weight: 900;
      letter-spacing: 0.04em;
      font-size: 14px;
      text-align: center;
      border-radius: 80px;
      cursor: pointer;
      padding: 1rem 1.5rem;
      margin-top: 2rem;
  }

  .custom__button:hover{
    background-color: var(--color-grey);
    transform: scale(1.05);
  }
  
  .head__manrope {
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 300;
    letter-spacing: 0.04em;
    line-height: 40px;
    font-size: 32px;
  }

  .subhead__manrope {
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 29.9px;
    font-size: 24px;
  }
  
  .p__manrope {
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 300;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 18px;
  }
  
  .headtext__bebas {
    font-family: var(--font-alt);
    color: var(--color-white);
    font-size: 80px;
    line-height: 100px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
  }

  .app__padding{
    padding: 2rem 6rem 2rem 6rem;
  }

  @media screen and (max-width: 2000px) {
    .headtext__bebas {
      font-size: 60px;
      line-height: 80px;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .app__padding{
      padding: 2rem 4rem 2rem 4rem;
    }

    .headtext__bebas {
      font-size: 40px;
      line-height: 50px;
    }

    .p__manrope {
      line-height: 20px;
      font-size: 14px;
    }

    .head__manrope {
      line-height: 30px;
      font-size: 23px;
    }

    .subhead__manrope{
      font-weight: 500;
      line-height: 26px;
      font-size: 18px;
    }

  }
  
  @media screen and (max-width: 850px) {
    .app__padding{
      padding: 2rem 3rem 2rem 3rem;
    }

    .custom__button {
      font-size: 12px;      
      cursor: pointer;
      padding: 0.8rem 1rem;
      margin-top: 1rem;
    }

    .headtext__bebas {
      font-size: 30px;
      line-height: 40px;
    }

    .p__manrope {
      line-height: 20px;
      font-size: 12px;
    }

    .head__manrope {
      line-height: 25px;
      font-size: 18px;
    }

    .subhead__manrope{
      font-weight: 500;
      line-height: 23px;
      font-size: 14px;
    }

  }
  
  @media screen and (max-width: 650px) {
    .app__padding{
      padding: 1rem 1rem 1rem 2rem;
    }

    .custom__button {
      font-size: 10px;      
      cursor: pointer;
      padding: 0.5rem 0.8rem;
      margin-top: 1rem;
    }

    .headtext__bebas {
      font-size: 20px;
      line-height: 30px;
    }

    .p__manrope {
      line-height: 15px;
      font-size: 9px;
    }

    .head__manrope {
      line-height: 18px;
      font-size: 15px;
    }

    .subhead__manrope{
      font-weight: 500;
      line-height: 18px;
      font-size: 12px;
    }
  }
  
  @media screen and (max-width: 450px) {
    .custom__button {
      font-size: 8px;      
      cursor: pointer;
      padding: 0.2rem 0.5rem;
      margin-top: 0.5rem;
    }

    .headtext__bebas {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 0.5rem;
    }

    .p__manrope {
      line-height: 12px;
      font-size: 8px;
    }

    .head__manrope {
      line-height: 16px;
      font-size: 12px;
    }

    .subhead__manrope{
      font-weight: 300;
      line-height: 16px;
      font-size: 8px;
    }
  }

  .typewriter {
    overflow: hidden; /* Ensures the text is hidden until it appears */
    white-space: nowrap; /* Prevents the text from wrapping */
    width: 0; /* Starts with no width */
    animation: typing 4s steps(30, end); /* Animation for typing */
  }
  
  @keyframes typing {
    from {
        width: 0; /* Starts with no width */
    }
    to {
        width: 100%; /* Expands to full width */
    }
  }

  .tracking-in-expand {
    -webkit-animation: tracking-in-expand 1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
            animation: tracking-in-expand 1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  }
  
   @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }