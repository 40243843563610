.app__header{
    align-items: center;
}

.app__header__welcome p{
    margin-right: 16rem;
    margin-bottom: 2rem;
}

.app__header__welcome-links a{
    padding-right: 2rem;
}

.app__header__welcome-links a:hover img{
    transform: scale(1.1);
}

.app__header__img img{
    width: 25vw;
    margin-right: 4rem;
}

.app__header__img img:hover{
    transform: scale(1.01);
}

.app__header__welcome-buttons button{
    margin-right: 2rem;
}

@media screen and (max-width: 1200px) {
    .app__header {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

    }

    .app__header__welcome p{
        margin-right: 6rem;
        margin-bottom: 1rem;
    }

    .app__header__img img{
        width: 25vw;
        margin-right: 0;
    }

  }

  @media screen and (max-width: 850px) {
    .app__header__welcome-links img{
        width: 6vw;
    }

    .app__header__welcome-links a{
        padding-right: 1rem;
    }

    .app__header__welcome-buttons button{
        margin-right: 1rem;
    }
  }

  @media screen and (max-width: 650px) {
    .app__header {
        align-items: flex-start;  
    }

    .app__header__img img{
        width: 45vw;
        margin-right: 2rem;
    }

    .app__header__welcome p{
        margin-right: 2rem;
        margin-bottom: 1rem;
    }

  }