.app__experience{
    margin-top: 4rem;
}

.app__experience .green {
    color: var(--color-green);
    margin: 0.6rem 0;
}

.app__experience__desc {
    width: 55%;
}

.app__experience__desc-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:0 0 1rem 0;
}

.app__experience__desc-two{
    margin-top: 4rem;
}

.app__experience__desc-title p{
    color: var(--color-grey);
}

.app__experience__desc p{
    margin-bottom: 1rem;
}


@media screen and (max-width: 450px) {
    .app__experience__desc-two{
        margin-top: 2rem;
    }

    .app__experience__desc-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin:0 0 0.5rem 0;
    }
}