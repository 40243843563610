.app__contact {
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.contact__divider {
    border: none;
    height: 2px; 
    background-color: var(--color-green); 
    width: 30%;
    margin: 1rem 0; 
}

.app__contact__number{
    display: flex;
    flex-direction: row;
}

.app__contact__links{
    display: flex;
    flex-direction: row;
    margin-bottom: 4rem;
}

.app__contact__links a{
    margin-top: 2rem;
    margin-left: 1rem;
}

.app__contact__links a:hover{
    transform: scale(1.06);
}

@media screen and (max-width: 850px) {
    .app__contact__links a{
        margin-top: 1rem;
        margin-left: 1rem;
    }

    .app__contact__links img{
        width: 6vw;
    }
}

@media screen and (max-width: 850px) {
    .app__contact__links a{
        margin-top: 0.5rem;
        margin-left: 1rem;
    }

    .app__contact__links{
        margin-top: 2rem;
    }
}
  
  